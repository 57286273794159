import { axiosInstance } from "../../axios";

const GETUsers = "api/AdminUser/SearchAdminUsers";
const POSTCreateUser = "api/AdminUser/CreateNewUser";
// const GETCustomers = "api/AdminUser/SearchAdminCustomers/";
const GETCustomers = "api/AdminUser/SearchAdminCustomersByPage/";
const POSTCreateCustomer = "api/AdminUser/CreateNewCustomer";
const POSTRemoveUser = "api/AdminUser/Delete?id=";

const searchUser = async function() {
  try {
    const response = await axiosInstance.bhAPIInstance.get(GETUsers);
    return parseList(response);
  } catch (error) {
    return null;
  }
};

const createUser = async function(user) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTCreateUser, user);
    return response.data;
  } catch (error) {
    return null;
  }
};

const removeUser = async function(usercompanyid) {
  try {
    const response = await axiosInstance.bhAPIInstance.delete(POSTRemoveUser + usercompanyid);
    return response.data;
  } catch (error) {
    return null;
  }
};

const searchCustomer = async function(search, page) {
  try {
    const response = await axiosInstance.bhAPIInstance.get(
      `${GETCustomers + page}/search/${search}`);
    return parseList(response);
  } catch (error) {
    return null;
  }
};

const createCustomer = async function(user) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTCreateCustomer, user);
    return response.data;
  } catch (error) {
    return null;
  }
};

const parseList = response => {
  if (response.status !== 200) throw Error(response.message);
  if (!response.data) return [];
  let list = response.data;
  if (typeof list !== "object") {
    list = [];
  }
  return list;
};

export const userData = {
  searchUser,
  createUser,
  searchCustomer,
  createCustomer,
  removeUser
};
