var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"editForm"}},[_c('vs-sidebar',{staticClass:"items-no-padding",attrs:{"parent":"editForm","hidden-background":"","click-not-close":"","position-right":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('div',{staticClass:"h-full"},[_c('div',{staticClass:"customizer-header mt-6 flex items-center justify-between px-6"},[_c('div',[_c('h4',[_vm._v("Add New User")])])]),_c('vs-divider',{staticClass:"mb-0"}),_c('div',{staticClass:"vx-col w-full mb-base"},[_c('vx-card',{attrs:{"no-shadow":""}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveCompany)}}},[_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col md:w-1/2 w-full"},[_c('ValidationProvider',{attrs:{"name":"firstname","rules":{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max:100 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"First name","name":"firstname"},model:{value:(_vm.currentUser.firstname),callback:function ($$v) {_vm.$set(_vm.currentUser, "firstname", $$v)},expression:"currentUser.firstname"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full"},[_c('ValidationProvider',{attrs:{"name":"lastname","rules":{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max:100 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"Last name","name":"lastname"},model:{value:(_vm.currentUser.lastname),callback:function ($$v) {_vm.$set(_vm.currentUser, "lastname", $$v)},expression:"currentUser.lastname"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col w-full"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":{ required: true, email: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"Email"},model:{value:(_vm.currentUser.emailAddress),callback:function ($$v) {_vm.$set(_vm.currentUser, "emailAddress", $$v)},expression:"currentUser.emailAddress"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col w-full"},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":"Phone","rules":{ regex: /^[0-9]+$/, required: true, max: 10 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"Phone","name":"Phone"},model:{value:(_vm.currentUser.mobileNumber),callback:function ($$v) {_vm.$set(_vm.currentUser, "mobileNumber", $$v)},expression:"currentUser.mobileNumber"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col w-full"},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":"Phone","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"w-full",attrs:{"name":"userType","placeholder":"Select Role"},model:{value:(_vm.currentUser.userType),callback:function ($$v) {_vm.$set(_vm.currentUser, "userType", $$v)},expression:"currentUser.userType"}},_vm._l((_vm.userRole),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item,"text":item}})}),1),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('vs-divider'),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('vs-button',{staticClass:"mr-3 mb-2",on:{"click":function($event){$event.preventDefault();return _vm.saveUser.apply(null, arguments)}}},[_vm._v("Submit")]),_c('vs-button',{staticClass:"mr-3 mb-2",attrs:{"color":"dark"},on:{"click":_vm.closeWindow}},[_vm._v("Cancel")])],1)])],1)]}}])})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }