<template>
<vx-card title="Users">
    <template slot="actions">
        <vs-button @click="editSelected({})">Add</vs-button>
        <EditUser v-if="selectedUser" :selectedUser="selectedUser" :openAddWindow="openAddWindow" @loadUsers="loadUsers"></EditUser>
    </template>
    <vs-table :sst="true" @sort="handleSort" stripe :data="users" v-if="users.length">
        <template #thead>
            <vs-th sort-key="displayName">Name</vs-th>
            <vs-th sort-key="emailAddress">Email</vs-th>
            <vs-th sort-key="mobileNumber">Mobile</vs-th>
            <vs-th sort-key="userType">Role</vs-th>
            <vs-th class="width1"></vs-th>
        </template>
        <template >
            <vs-tr :key="i" v-for="(tr, i) in users" :data="tr">
                <vs-td>
                    {{ tr.displayName }}
                </vs-td>
                <vs-td>
                    {{ tr.emailAddress }}
                </vs-td>
                <vs-td>
                    {{ tr.mobileNumber }}
                </vs-td>
                <vs-td>
                    {{ tr.userType }}
                </vs-td>
                <vs-td>
                    <vs-button color="dark" @click="removeUser(tr.id)">Delete</vs-button>
                </vs-td>
            </vs-tr>
        </template>
    </vs-table>
    <NoData v-else class="mt-base" :headTitle="noData.title" :description="noData.description" ></NoData>
</vx-card>
</template>

<script>
import EditUser from './EditUser'

import {
    userData
} from '../../store/api/user';
import _ from 'lodash';

export default {
    data() {
        return {
            users: [],
            selectedUser: undefined,
            openAddWindow: true,
            noData: {
              title: "No Records Found",
            }
        }
    },
    components: {
        EditUser
    },
    async created() {
        await this.loadUsers();
    },
    methods: {
        async loadUsers() {
            this.users = [];
            this.users = await userData.searchUser();

            if (this.openAddWindow) {
                this.openAddWindow = !this.openAddWindow;
            }
        },
        handleSort(key, active) {
          this.users = _.orderBy(this.users, key, active);
        },
        async removeUser(id) {
            if (confirm("are you sure ? ")) {
                var result = await userData.removeUser(id);
                await this.loadUsers();
            }
        },
        editSelected(tr) {
            this.selectedUser = tr;
            if (!this.openAddWindow) {
                this.openAddWindow = !this.openAddWindow;
            }
        }
    }
}
</script>
